<template>
  <b-sidebar
    id="invoice-details-sidebar"
    width="50%"
    title="Invoice Details"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Invoice Details - {{ invoice.invoice_id }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card class="box-shadow-0">
        <Invoice
          class="mb-1"
          :invoice="invoice"
        />
        <div class="d-flex">
          <b-button
            v-b-modal.invoice-modal
            class="mr-1"
            variant="primary"
            @click="$emit('getRequestedAction', {}, 'Download Invoice', 'download',
                          `b2e_dashboard/invoice_download/${invoice.invoice_id}/`,
                          'get', true)"
          >
            <feather-icon icon="DownloadIcon" />
            <span class="align-middle ml-50">Download Invoice</span>
          </b-button>
          <b-button
            v-b-modal.invoice-modal
            variant="outline-primary"
            @click="$emit('getRequestedAction', {}, 'Sync Invoice', 'sync',
                          `b2e_dashboard/invoice_adjust/${invoice.invoice_id}/`,
                          'get', true)"
          >
            <feather-icon icon="RefreshCcwIcon" />
            <span class="align-middle ml-50">Sync Invoice</span>
          </b-button>
        </div>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import Invoice from '@/components/common/Invoice.vue'

export default {
  name: 'InvoiceSidebar',
  components: { Invoice },
  props: {
    invoice: {
      type: Object,
    },
  },
}
</script>
